import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createTheme, ThemeProvider } from "@mui/material/styles";
const Home = lazy(() => import("./pages/Home"));
const Success = lazy(() => import("./pages/Success"));
const Cancel = lazy(() => import("./pages/Cancel"));

// Define a fallback component for the entire routing
const LoadingMessage = () => (
  <div className="spinner-container">
    <div className="spinner"></div>
  </div>
);

// Create a theme instance.
const theme = createTheme({
  typography: {
    fontFamily: "Montserrat, Arial, sans-serif",
  },
});

function App() {
  return (
    <BrowserRouter>
      {/* <CartProvider> */}
      <ThemeProvider theme={theme}>
        <Routes>
          <Route
            index
            element={
              <Suspense fallback={<LoadingMessage />}>
                <Home />
              </Suspense>
            }
          />
          <Route
            path="success"
            element={
              <Suspense fallback={<LoadingMessage />}>
                <Success />
              </Suspense>
            }
          />
          <Route
            path="cancel"
            element={
              <Suspense fallback={<LoadingMessage />}>
                <Cancel />
              </Suspense>
            }
          />
          {/* <Header /> */}
        </Routes>
      </ThemeProvider>
      {/* </CartProvider> */}
    </BrowserRouter>
  );
}

export default App;
